export default class Utils {
    static getUrlParams(){
        let url = window.location.href;
        let pstr = url.split('?')[1]
        let obj = {};
        if(pstr && pstr.length > 0){
            let arr = pstr.split('&')
            for(let i = 0;i < arr.length;i++){
                let s = arr[i]
                if(s.search('=') >= 0){
                    const ss = s.split('=')
                    obj[ss[0]] = ss[1]
                }
            }
        }
        return obj;
    }

    static getDownloadLink(){
        let type = this.isIOS() ? 2 : 1
        let url = this.getLinkUrl(type)
        return url
    }

    static getLinkUrl(type){
        let url = 'https://6677by.com/web/wxmp_fish3d/fish.html?from=official'
        if(type == 1){
            url = '/files/bylm.apk'
        }
        else if(type == 2){
            url = 'https://apps.apple.com/cn/app/6677%E6%9D%8E%E9%80%B5%E6%8D%95%E9%B1%BC/id1624861601'
        }
        return url
    }

    static isAndorid(){
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
        return isAndroid
    }
    
    static isIOS(){
        let u = navigator.userAgent;
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        return isIOS
    }

    static isMobile(){
        let userAgentInfo = navigator.userAgent;
        let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
        let getArr = Agents.filter(i => userAgentInfo.includes(i));
        return getArr.length ? true : false;
    }

    static isWXBroswer(){
        let ua = navigator.userAgent.toLowerCase();
        let isWeixin = ua.indexOf('micromessenger') != -1;
        return isWeixin
    }
    
    static toWan(num){
        let yu = num%10000;
        let r = ''
        if(yu == 0){
            r = (num/10000).toFixed(0)
        }
        else {
            r = (num/10000).toFixed(2)
        }
        return r + '万'
    }

} 