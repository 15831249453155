import { reqUploadStatistics } from "./http/request";
import Utils from "./utils/Utils";

class DataCenter {
    static _instance;
    static get instance(){
        if(!this._instance){
            this._instance = new DataCenter()
        }
        return this._instance
    }
    
    initUrlConf(data){
        this.urlConf = data
    }

    uploadStatistics(type){
        let obj = Utils.getUrlParams();
        let from = obj['from'] || '';
        reqUploadStatistics(type,from).then(res=>{
            console.log(res)
        })
    }

}

const dataCenter = DataCenter.instance;
export default dataCenter