<template>
    <div class="c_main_cls">
        <div class="bg_cls" @click.stop>
            <div class="title_cls">充值</div>
            <input type="text" placeholder="请输入你的UID" maxlength="10" class="number_cls" v-model="uid" @change="checkNumber" @keyup="checkNumber">
            <div class="name_cls" v-if="!!name">昵称：{{name}}</div>
            <div class="btn_query" @click="queryInfo">查询</div>
            <div class="tip_cls">※充值前请核实你的uid和昵称是否正确※</div>
            <div class="list_cls">
                <div v-for="(item,index) in items" :key="index" class="item_cls" @click="buyNow(item.producctId)">
                    <div class="coin_cls">{{ getCoinLabel(item.coin) }}</div>
                    <div class="money_cls">{{item.price}}元</div>
                </div>
            </div>
        </div>
        <div v-if="payDlgFlag" class="pay_select_dlg" @click.stop="payDlgFlag = false">
            <div class="pay_select" @click.stop>
                <img src="@/assets/img_pay_title.png">
                <div class="way_cls">
                    <img src="@/assets/img_zhifu_zhifubao.png" @click="pay(1)">
                    <img src="@/assets/img_zhifu_zweixin.png" @click="pay(2)">
                </div>
            </div>
        </div>
        <div v-if="isMobile" class="btn_close_cls" @click="closeDlg">✖</div>
    </div>
</template>
<script>
import { getInfoByUID } from '@/js/http/request'
import comPay from '@/js/CommonPay'
import Utils from '@/js/utils/Utils'
export default {
    data(){
        return {
            uid:'',
            name:'',
            token:'',
            items:[],
            productID:'',
            payDlgFlag:false,
        }
    },
    computed:{
        isMobile(){
            return Utils.isMobile()
        }
    },
    methods:{
        checkNumber(){
            this.uid = this.uid.replace(/[^0-9]/g, '')
        },
        closeDlg(){
            this.$emit('closeEvent')
        },
        queryInfo(){
            let uid = this.uid
            if(uid.length < 6){
                alert('请输入正确的uid')
                return;
            }
            getInfoByUID(uid).then(res=>{
                if(res.code != 0){
                    alert('您输入的uid有误，请重新输入');
                    this.uid = ''
                    return;
                }
                let data = res.data
                this.name = data.nickname;
                this.token = data.token;
                let shops = data.shop;
                let len = shops.length;
                let arr = [];
                for(let i = 0;i < len;i++){
                    let obj = shops[i].string;
                    let coin = obj.golden;
                    if(obj.addition){
                        coin += obj.addition[0].value;
                    }
                    let price = parseFloat(obj.price).toFixed(2);
                    arr.push({
                        coin,price,producctId:obj.id
                    })
                }
                this.items = arr;
            })
        },
        getCoinLabel(coin){
            return Utils.toWan(coin) + '金币'
        },
        buyNow(producctId){
            this.payDlgFlag = true
            this.productID = producctId;
        },
        pay(type){
            if(type == 1){
                comPay.payByAli(this.uid,this.productID)
            }
            else if(type == 2){
                comPay.payByWx(this.uid,this.productID)
            }
            this.payDlgFlag = false;
        }
    }
}
</script>
<style scoped>
.c_main_cls {
    width: 100%;
    height: 100%;
    position:fixed;
    left: 0;
    top: 0;
    padding-top: 66px;
    background-color: #072A57;
    z-index: 90;
    color: #00cb65;
}
.btn_close_cls {
    position: absolute;
    top: 1vh;
    right: 2vh;
    font-size: 4vh;
    cursor: pointer;
    font-weight: bolder;
}
.bg_cls {
    width: 100vw;
    height: 100vh;
    max-width: 60vh;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;
}
.title_cls {
    font-size: 5vh;
    color: #fff;
    font-weight: bold;
    line-height: 12vh;
}
.number_cls {
    width: 90%;
    height: 7vh;
    border-radius: 4vh;
    border: none;
    text-align: center;
    box-shadow: 0 0 5px #fff;
    font-size: 4vh;
    font-weight: bold;
    color: #00cb65;
}
.number_cls::placeholder {
  color: #00cb65;
  font-size: 3vh;
}
.name_cls {
    text-align: left;
    font-size: 2vh;
    margin: 1vh 0 0 60px;
    font-weight: bold;
}
.btn_query {
    width: 20vh;
    height: 6vh;
    margin: 0 auto;
    border-radius: 4vh;
    background-color:#00cb65;
    color: white;
    font-size: 3vh;
    line-height: 6vh;
    margin-top: 4vh;
    cursor: pointer;
    transition: 0.5s all;
}
.btn_query:hover {
    transform: scale(1.1);
}
.tip_cls {
    font-size: 2vh;
    color: #f67d25;
    margin-top: 4vh;
    font-weight: bold;
    text-align: center;
}
.list_cls {
    display: flex;
    padding: 0 2vw;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2vh;
}
.item_cls {
    width: 30%;
    border-radius: 10px;
    margin: 1.5%;
    background-color: paleturquoise;
    color: black;
    padding: 2vh 0;
    font-size: 2vh;
    cursor: pointer;
}
.item_cls:hover {
    background-color:turquoise;
}
.coin_cls {
    line-height: 4vh;
    font-weight: bolder;
    font-size: 2.4vh;
}
.money_cls {
    line-height: 4vh;
    color:indianred;
    font-weight: bold;
}
.pay_select_dlg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 200;
}
.pay_select {
    width: 400px;
    height: 200px;
    background-color:cornsilk;
    margin: 0 auto;
    margin-top: 30vh;
    padding: 2vh;
    border-radius: 2vh;
}
.way_cls {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2vh;
}
.way_cls img {
    display: block;
    cursor: pointer;
}
</style>