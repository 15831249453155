import { checkBeforeCharge, postAliPayOrder, postWxPayOrder } from "./http/request";

class CommonPay {
    
    payByWx(uid,product_id,token){
        const func = ()=>{
            let t = {product_id,uid,notify: 1};
            postWxPayOrder(t).then(rep=>{
                let code = this.checkValue(rep["code"], 1);
                let data = rep["data"];
                let msg = rep["message"];
                if (code != 0 || !data) return this.dealPayEnd(msg, code);
        
                let toUrl = data.jump_url
                let jumpUrl = this.fix(toUrl);
                window.open(jumpUrl,'_blank');
            })
        }
        checkBeforeCharge(uid,product_id,token).then(res=>{
            if(res.code == 1) return alert(res.message)
            func()
        })
    }
    
    payByAli(uid,product_id,token){
        const func = ()=>{
            let params = {
                uid,token,product_id,os: 1,
                trade_type: 'WEB',package: 'h5'
            };
            postAliPayOrder(params).then(rep=>{
                let code = rep["code"];
                let data = rep["data"];
                let msg = rep["message"];
                if (code != 0 || !data) return this.dealPayEnd(msg, code);
                let jumpUrlEncode = data['alipay'];
                if (!jumpUrlEncode) return;
                let jumpUrl = this.decode(jumpUrlEncode)
                window.open(jumpUrl,'_blank')
            })
        }

        checkBeforeCharge(uid,product_id,token).then(res=>{
            if(res.code == 1) return alert(res.message)
            func()
        })
    }

    dealPayEnd(msg, code, cb_completed) {
        code = this.checkValue(code, 17);
        if (code != 0) {
            alert(msg || "支付失败");
            if (cb_completed) cb_completed(false);
            return;
        }
        if(cb_completed) cb_completed(true);
    }

    checkValue(t, def) {
        return t == undefined || t == null ? def : t;
    }

    fix(toUrl) {
        let arg1 = {};
        arg1['pcfu'] = 2;
        arg1['mu'] = 6;
        arg1['pe'] = 3;
        arg1['pm'] = 1;
        let argStr1 = this.table2params(arg1);
        return toUrl + '&' + argStr1
    }

    table2params(obj) {
        if (!obj) return '';
        let arr = [];
        for (let key in obj) {
            if (!(obj[key] == undefined || obj[key] == null))
                arr.push(key + '=' + obj[key]);
        }
        return arr.join('&');
    }

    decode(input) {
        if (!input) return '';
        let output = "";
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;
        const charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        input = input.replace(/[^A-Za-z0-9]/g, "");
        while (i < input.length) {
            enc1 = charSet.indexOf(input.charAt(i++));
            enc2 = charSet.indexOf(input.charAt(i++));
            enc3 = charSet.indexOf(input.charAt(i++));
            enc4 = charSet.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }
        }
        output = decodeURIComponent(window["escape"](output));
        return output;
    }
}
const comPay = new CommonPay;
export default comPay;