<template>
    <div class="qrcode_cls">
        <div class="qr_div_cls">
            <div class="tips_cls">扫描二维码进入游戏</div>
            <img src="/images/img_lgcode.png">
        </div>
        <div class="btn_cls">
            <img src="@/assets/btn_android.png" @click="clkHandle(1)">
            <img src="@/assets/btn_ios.png" @click="clkHandle(2)">
            <img src="@/assets/btn_web.png" @click="clkHandle(3)">
        </div>
    </div>
</template>
<script>
import dataCenter from '@/js/DataCenter';
import Utils from '../js/utils/Utils';

export default {
    name:"QRCodeComp",
    methods:{
        clkHandle(type){
            let url = Utils.getLinkUrl(type)
            window.open(url, "_blank");
            dataCenter.uploadStatistics(type)
        }
    }
}
</script>
<style scoped>
.qrcode_cls {
    width: 160px;
    position: fixed;
    border-radius: 10px;
    background: linear-gradient(142deg, #FFCD00 21.64%, #F59C1B 78.21%);
    padding: 5px 8px 8px 8px;
    right: 20px;
    bottom: 20px;
    z-index: 300;
}
.qr_div_cls {
    width: 100%;
    border-radius: 40px;
    padding: 10px;
    box-sizing: border-box;
}
.qr_div_cls img {
    width: 100%;
}
.tips_cls {
    padding-bottom: 10px;
    color: #FFF;
    text-shadow: 0px 1px 4px #DA9000;
    font-size: 14px;
    font-weight: 800;
}
.btn_cls {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.btn_cls img {
    width: 30%;
    display: block;
    cursor: pointer;
}

</style>