<template>
    <div class="left_con_cls"  @touchmove.prevent @click="closeKefu()">
        <div class="qrcode_cls">
            <div class="lgcode_cls">
                <img src="/images/img_pubcode.png">
                <p>公众号:bylm6677</p>
            </div>
            <div class="lgcode_cls">
                <img src="/images/img_kefucode.png">
                <p>客服:bylm677</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'KefuComp',
    setup() {
        
    },
    methods:{
        closeKefu(){
            this.$emit('showKefuEvent')
        }
    }
}
</script>
<style scoped>
.left_con_cls {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 200;
    background: rgba(0, 0, 0, 0.8);
}
.qrcode_cls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 25vh;
}
.lgcode_cls {
    width: 44vw;
    padding: 4vw;
    font-size: 4vw;
    border-radius: 2vw;
    margin: 0 3vw;
    color: #000;
    background-color: bisque;
}
.lgcode_cls img {
    width: 100%;
}
</style>