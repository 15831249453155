<template>
  <div id="app">
    <HomeComp v-if="loadFlag"/>
  </div>
</template>

<script>
import HomeComp from './components/HomeComp.vue'
import dataCenter from './js/DataCenter';
// import {getUrl3d} from './js/http/request'
export default {
  name: 'App',
  components: {
    HomeComp
  },
  data(){
    return {
      loadFlag:false
    }
  },
  mounted(){
    let _this = this;
    // getUrl3d().then(()=>{})
    _this.loadFlag = true
    dataCenter.uploadStatistics(10000)
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  background: #072A57;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}
</style>
